import React, { useContext, useEffect, useState } from "react";
import AppContext from "../context/store";
import "./AppBody.css";
import {
  removeArrayDuplicates,
  generateAccessToken,
  checkSupportsHtml5Storage,
} from "../utils/helpers";
import { ReactComponent as Helmet } from "../images/Helmet.svg";
import { ReactComponent as Bike } from "../images/Bike.svg";
import { ReactComponent as DefaultAvatar } from "../images/Item.svg";
import { isEmpty } from "lodash";


const AppBody = () => {
  // local component states
  const [cardState, setCardState] = useState({
    orders: {
      dropDown: true,
      listLength: 2,
    },
    opportunities: {
      dropDown: true,
      listLength: 2,
    },
    interactions: {
      dropDown: true,
      listLength: 3,
    },
    abandon_cart_card: {
      dropDown: true,
      listLength: 1,
    },
  });
  const [names, setNames] = useState([]);
  const [sendCouponState, setSendCouponState] = useState({
    coupondSent: "new",
    errorMessage: "",
    coupondButtonHide: false,
  });
  // global store state
  const { state } = useContext(AppContext);
  const { conversationData, clientSetUp } = state;
  const {
    phone,
    email,
    gender,
    address,
    td_client_id,
    CLTV,
    purchase_history,
    score,
    next_best_product,
    browsing_history,
    Segment_info,
    loyalty_member,
    "Abandon cart": abandon_cart,
  } = conversationData;
  // create arryList of names to display
  useEffect(() => {
    let { First_name, Last_name, full_name } = conversationData;
    if (full_name && full_name.constructor === Array) {
      full_name = removeArrayDuplicates(full_name);
    }
    if (First_name && First_name.constructor === Array) {
      First_name = removeArrayDuplicates(First_name);
    }
    if (Last_name && Last_name.constructor === Array) {
      Last_name = removeArrayDuplicates(Last_name);
    }
    let namesArr = (First_name && First_name.slice()) || [];
    if (
      namesArr &&
      namesArr.constructor === Array &&
      conversationData.Last_name
    ) {
      for (let i = 0; i < namesArr.length; i++) {
        namesArr[i] = conversationData.Last_name[i]
          ? `${namesArr[i]} ${conversationData.Last_name[i]}`
          : namesArr[i];
      }
    }
    setNames(removeArrayDuplicates(namesArr.concat(full_name)));
  }, [conversationData]);

  let promoSegment = null;
  // Find promo code segment info
  if (Segment_info && Segment_info.length > 0) {
    const matchingId = new Set([
      "102552",
      "102559",
      "102560",
      "102561",
      "102562",
    ]);
    const segIds = Segment_info[0];
    if (segIds.length > 0) {
      for (let i = 0; i < segIds.length; i++) {
        // promo code segment info found
        if (matchingId.has(segIds[i])) {
          promoSegment = Segment_info[1][i];
        }
      }
    }
  }
  // Load local storage send coupon info
  useEffect(() => {
    const sendCouponObj = {
      coupondSent: "new",
      errorMessage: "",
      coupondButtonHide: false,
    };
    if (
      checkSupportsHtml5Storage() &&
      localStorage.getItem("promosSent") &&
      localStorage.getItem("promosSent").includes(promoSegment)
    ) {
      sendCouponObj.coupondSent = "sent";
      sendCouponObj.coupondButtonHide = true;
    }
    setSendCouponState(sendCouponObj);
  }, [promoSegment]);
  /**
   * Small switch function to help generate profile fields
   * @param {Object} fields all profile fields
   * @param {string} field current irritate field
   * @returns jsx for specific profile fields
   */
  const profileSwitch = (fields, field) => {
    const value = fields[field];
    switch (field) {
      case "Member score":
        return (
          <div className="field-value">
            <span>{value}</span>
            {(value && parseInt(value) >= 300 && (
              <span className="emoji-fire"></span>
            )) ||
              ""}
          </div>
        );
      case "LTV":
        return (
          <div className="field-value">
            <span>
              {value &&
                `$${value
                  .toFixed()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}`}
            </span>
            {(value && parseInt(value) >= 300 && (
              <span className="emoji-fire"></span>
            )) ||
              ""}
          </div>
        );
      default:
        return <div className="field-value">{value}</div>;
    }
  };
  /**
   * generate and return profile card content
   * @returns {React.ReactElement} Profile card content
   */
  const ProfileContent = () => {
    // all possible profile fields
    const fields = {
      Names: names,
      Gender: gender,
      Phone: phone,
      Email: email,
      Address: address,
      "Member score": loyalty_member === "true" && score,
      LTV: CLTV,
      "TD client Id": td_client_id,
    };
    // Remove duplicates inside arrays
    Object.keys(fields).forEach(function (field) {
      if (fields[field] && fields[field].constructor === Array) {
        fields[field] = removeArrayDuplicates(fields[field]);
      }
    });
    return Object.keys(fields).map((field) => (
      <div key={field} className="field">
        <div className="field-key">{field}:</div>
        {fields[field] && fields[field].constructor === Array ? (
          <div className="field-value">
            {fields[field].map((value) => {
              switch (field) {
                case "Email":
                  return (
                    <div className="field-value-array" key={value}>
                      <a href={value}>{value}</a>
                    </div>
                  );
                default:
                  return (
                    <div className="field-value-array" key={value}>
                      {value}
                    </div>
                  );
              }
            })}
          </div>
        ) : (
          profileSwitch(fields, field)
        )}
      </div>
    ));
  };
  /**
   * generate and return order card content
   * @returns {React.ReactElement} Order card content
   */
  const OrderContent = () => {
    if (purchase_history) {
      const disaply_purchase_history = purchase_history.slice(
        0,
        cardState.orders.listLength
      );
      return disaply_purchase_history.map((order) => (
        <div
          key={order.id}
          className={`order-item ${order.error ? "error" : ""}`}
        >
          {getItemAvatar(order.order_name)}
          <a href="/">#{order.id}</a>
          <div className="">{`${order.order_name} ${order.order_price} ${order.timestamp}`}</div>
        </div>
      ));
    }
    return <></>;
  };
  /**
   * generate and return oppotunities card content
   * @returns {React.ReactElement} Opportunities card content
   */
  const OpportunitiesContent = () => {
    if (next_best_product) {
      const disaply_next_best_product = next_best_product.slice(
        0,
        cardState.opportunities.listLength
      );
      return disaply_next_best_product.map((product) => (
        product && !isEmpty(product) &&
        <div key={product} className="opportunities-item">
          <div className="item-avatar">{getItemAvatar(product)}</div>
          <div className="opportunities-detail">
            <div>{product}</div>
          </div>
        </div>
      ));
    }
    return <></>;
  };
  /**
   * generate and return interactions card content
   * @returns {React.ReactElement} Interactions card content
   */
  const InteractionsContent = () => {
    if (browsing_history) {
      const disaply_browsing_history = browsing_history.slice(
        0,
        cardState.interactions.listLength
      );
      const mobileList = ["IPad", "IPod", "IPhone", "Android Phone"];
      const desktopList = ["Windows Desktop", "Apple Desktop", "Linux Desktop"];
      const getDeviceTypeClass = (device_category) =>
        mobileList.includes(device_category)
          ? "mobile-device"
          : desktopList.includes(device_category)
          ? "desktop-device"
          : "others-device";
      return disaply_browsing_history.map((item) => (
        <div key={item.timestamp} className="interactions-item">
          <div className="interactions-detail">{item.path}</div>
          <div
            className={`interactions-device ${getDeviceTypeClass(
              item.device_category
            )}`}
          >
            <div
              className={`${getDeviceTypeClass(item.device_category)}-icon`}
            ></div>
            <div>{item.device_category || "Others"}</div>
          </div>
          <div className="interactions-timestamp">{item.timestamp}</div>
        </div>
      ));
    }
    return <></>;
  };
  /**
   * generate and return abandon cart card content
   * @returns {React.ReactElement} AbandonCart card content
   */
  const AbandonCartContent = () => {
    if (abandon_cart) {
      const disaply_abandon_cart = abandon_cart.slice(
        0,
        cardState.abandon_cart_card.listLength
      );
      return disaply_abandon_cart.map((order) => (
        <div key={order} className="interactions-item">
          {getItemAvatar(order)}
          <div className="interactions-detail">
            <div>{order}</div>
          </div>
        </div>
      ));
    }
    return <></>;
  };
  /**
   * handle send coupon error response
   * @param {Object} error error response
   */
  const handleSendCouponError = (error) => {
    console.log("error", error);
    const opportunitiesObj = {
      errorMessage: `Failed to send the promo code to current user, ${error.error}`,
      coupondButtonHide: false,
      coupondSent: "error",
    };
    // if error sent, update the text
    if (error.status === 429) {
      opportunitiesObj.coupondButtonHide = true;
    }
    // update local send state
    setSendCouponState(opportunitiesObj);
  };
  /**
   * send promo coupon
   */
  const sendCoupon = async () => {
    // set send coupon button status to loading
    setSendCouponState({
      coupondSent: "loading",
      coupondButtonHide: true,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const promoSegmentArr = promoSegment.split("-");
    const activation_id = promoSegmentArr[0];
    const batch_segment_id = promoSegmentArr[1];
    const { redirect_uri, pcEnvironment } = clientSetUp;
    const params = {
      activation_id,
      batch_segment_id,
      access_token: generateAccessToken({ redirect_uri, pcEnvironment }),
    };
    var URL = "https://domain-authentication-server.com/coupon";
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(params),
    };
    // send request to server /coupon endpoint
    fetch(URL, requestOptions)
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then(function (data) {
        console.log("api response: " + JSON.stringify(data));
        if (data && data.message && data.message === "token unverified") {
          handleSendCouponError(data.message);
        } else {
          // if successfully sent, update the text
          setSendCouponState({
            coupondSent: "sent",
            coupondButtonHide: true,
          });
          if (checkSupportsHtml5Storage()) {
            const promoSent = localStorage.getItem("promosSent");
            localStorage.setItem(
              "promosSent",
              promoSent ? [...promoSent, promoSegment] : [promoSegment]
            );
          }
        }
      })
      .catch((error) => {
        handleSendCouponError(error);
      });
  };
  /**
   * handle the show more item toggle for given card
   * @param {string} card card name
   */
  const showMore = (card) => {
    setCardState({
      ...cardState,
      [card]: {
        ...cardState[card],
        listLength: cardState[card].listLength + 4,
      },
    });
  };
  /**
   * handle the card content toggle for given card
   * @param {string} card card name
   */
  const toggleDropDown = (card) => {
    setCardState({
      ...cardState,
      [card]: {
        ...cardState[card],
        dropDown: !cardState[card].dropDown,
      },
    });
  };
  /**
   * return the corresponding icon for given product
   * @param {string} product product name
   * @returns {React.ReactElement} display icon/avatar
   */
  const getItemAvatar = (product) => {
    const HELMET = "helmet",
      BIKE = "bike";
    if (product && (typeof product === "string" || product instanceof String)) {
      if (product.toLowerCase().indexOf(HELMET) !== -1) {
        return <Helmet />;
      } else if (product.toLowerCase().indexOf(BIKE) !== -1) {
        return <Bike />;
      }
    }
    return <DefaultAvatar />;
  };
  /**
   * return proper style according to coupon send state
   * @returns {string} style name
   */
  const getSendCouponStyle = () => {
    switch (sendCouponState.coupondSent) {
      case "sent":
        return "success";
      case "error":
        return "error";
      default:
        return "";
    }
  };
  return (
    <div className="app-body">
      <div className="card profile">
        <div className="card-header">
          <div className="card-header-text">
            <i className="fas fa-address-book"></i>Profile
          </div>
        </div>
        <div className="card-content">
          <ProfileContent />
        </div>
      </div>
      <div className="card orders">
        <div className="card-header">
          <div className="card-header-text">
            <i className="fas fa-list-alt"></i>Orders
          </div>
          <div
            className="card-header-dropdown"
            onClick={() => toggleDropDown("orders")}
          >
            {cardState.orders.dropDown ? (
              <i className="fas fa-chevron-down"></i>
            ) : (
              <i className="fas fa-chevron-up"></i>
            )}
          </div>
        </div>
        {cardState.orders.dropDown && (
          <div className="card-content orders-content">
            <OrderContent />
            {purchase_history &&
              purchase_history.length > cardState.orders.listLength && (
                <div className="show-more" onClick={() => showMore("orders")}>
                  <i className="fas fa-chevron-down"></i>more orders
                </div>
              )}
          </div>
        )}
      </div>
      <div className="card interactions">
        <div className="card-header">
          <div className="card-header-text">
            <i className="fas fa-list-alt"></i>Interactions
          </div>
          <div
            className="card-header-dropdown"
            onClick={() => toggleDropDown("interactions")}
          >
            {cardState.interactions.dropDown ? (
              <i className="fas fa-chevron-down"></i>
            ) : (
              <i className="fas fa-chevron-up"></i>
            )}
          </div>
        </div>
        {cardState.interactions.dropDown && (
          <div className="card-content">
            <InteractionsContent />
            {browsing_history &&
              browsing_history.length > cardState.interactions.listLength && (
                <div
                  className="show-more"
                  onClick={() => showMore("interactions")}
                >
                  <i className="fas fa-chevron-down"></i>more interactions
                </div>
              )}
          </div>
        )}
      </div>
      <div className="card opportunities">
        <div className="card-header">
          <div className="card-header-text">
            <i className="fas fa-globe"></i>Opportunities
          </div>
          <div
            className="card-header-dropdown"
            onClick={() => toggleDropDown("opportunities")}
          >
            {cardState.opportunities.dropDown ? (
              <i className="fas fa-chevron-down"></i>
            ) : (
              <i className="fas fa-chevron-up"></i>
            )}
          </div>
        </div>
        {cardState.opportunities.dropDown && (
          <div className="card-content">
            {promoSegment && next_best_product && next_best_product.length > 0 && 
            !isEmpty(next_best_product.filter(item => !isEmpty(item))) && (
              <div id="opportunities-send-btn-container">
                <div
                  className={`opportunities-send-btn-description ${getSendCouponStyle()}`}
                >
                  {sendCouponState.coupondSent === "sent" ? (
                    "The promo code has been sent to current user !"
                  ) : sendCouponState.coupondSent === "new" ? (
                    "Click and send coupon to this user for following recommended products"
                  ) : sendCouponState.coupondSent === "loading" ? (
                    <div className="loading-three-dots"></div>
                  ) : (
                    sendCouponState.errorMessage
                  )}
                </div>
                {sendCouponState.coupondButtonHide || (
                  <div
                    onClick={() => sendCoupon()}
                    className="opportunities-send-btn"
                  >
                    send
                  </div>
                )}
              </div>
            )}
            <OpportunitiesContent />
            {next_best_product &&
              next_best_product.length > cardState.opportunities.listLength && (
                <div
                  className="show-more"
                  onClick={() => showMore("opportunities")}
                >
                  <i className="fas fa-chevron-down"></i>more opportunities
                </div>
              )}
          </div>
        )}
      </div>
      {/*abandon_cart && abandon_cart.length > 0 && (
        <div className="card">
          <div className="card-header">
            <div className="card-header-text">
              <i className="fas fa-list-alt"></i>Abandon Cart
            </div>
            <div
              className="card-header-dropdown"
              onClick={() => toggleDropDown("abandon_cart_card")}
            >
              {cardState.abandon_cart_card.dropDown ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </div>
          </div>
          {cardState.abandon_cart_card.dropDown && (
            <div className="card-content">
              <AbandonCartContent />
              {abandon_cart &&
                abandon_cart.length >
                  cardState.abandon_cart_card.listLength && (
                  <div
                    className="show-more"
                    onClick={() => showMore("abandon_cart_card")}
                  >
                    <i className="fas fa-chevron-down"></i>more abandon carts
                  </div>
                )}
            </div>
          )}
        </div>
                  )*/}
    </div>
  );
};

export default AppBody;
