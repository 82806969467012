import React, { useContext } from "react";
import AppContext from "../context/store";
import { getDataFromTd } from "../utils/helpers";
import { ReactComponent as ProfileSvg } from "../images/Profile.svg";
import "./AppHeader.css";

const AppHeader = () => {
  const { state, dispatch } = useContext(AppContext);
  const {
    conversationData,
    customerHandle,
    clientSetUp,
    genesysConversationData,
  } = state;
  const { name, id, last_update, vip_status } = conversationData;
  return (
    <div className="app-header">
      <div className="title">
        <div className="avatar-container">
          <ProfileSvg className="avatar" />
        </div>
        <div className="title-detail">
          <div className="user-name-section">
            <div className="user-name">{name || ""}</div>
            {vip_status && vip_status !== "false" && vip_status !== "False" && (
              <div className="badge-vip">
                <span role="img" aria-label="fire">
                  &#x1F525;
                </span>
                <span>VIP</span>
              </div>
            )}
          </div>
          <div className="conversation-id">{`Conversation ID: ${
            (id && id.replace(/"/g, "")) || ""
          }`}</div>
        </div>
      </div>
      <div className="title-action">
        <div className="last-update">{`Last update: ${last_update || ""}`}</div>
        <button
          className="refresh-btn"
          onClick={() => {
            getDataFromTd(
              customerHandle,
              dispatch,
              clientSetUp,
              genesysConversationData
            );
          }}
        >
          <i className="fas fa-sync-alt"></i>refresh
        </button>
      </div>
    </div>
  );
};

export default AppHeader;
