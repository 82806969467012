import { useEffect, useReducer } from "react";
import "./App.css";
import AppHeader from "./AppHeader/AppHeader";
import AppBody from "./AppBody/AppBody";
import LoadingMask from "./LoadingMask/LoadingMask";
import AppContext from "./context/store";
import reducer, { initialState } from "./context/reducer";
import { authentication } from "./utils/authentication";
import { DEFAULT_PC_ENVIRONMENT } from "./utils/constant";
import { getDomainInfo } from "./utils/helpers";
import actions from "./context/actions";

/**
 * React main application function
 */
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  /**
   * Application initialization function
   */
  const clientSetupInit = async () => {
    let pcEnvironment = DEFAULT_PC_ENVIRONMENT;
    // get variables from current uri
    const queryParams = new URLSearchParams(window.location.search);
    const environmentParam = queryParams.get("environment");
    if (
      typeof environmentParam !== "undefined" &&
      environmentParam !== null &&
      environmentParam !== ""
    ) {
      pcEnvironment = environmentParam;
    }
    const redirect_uri = window.location.host
      ? `${window.location.protocol}//${window.location.host}/`
      : "https://treasuredata-cdpforservice.com/";
    const domainInfo = await getDomainInfo(redirect_uri, pcEnvironment);
    const clientSetUp = {
      pcEnvironment: pcEnvironment,
      code: queryParams.get("code") || "",
      conversationId:
        queryParams.get("conversationId") || queryParams.get("state"),
      redirect_uri,
      client_id: domainInfo ? domainInfo.client_id : "",
      account_id: domainInfo ? domainInfo.account_id : "",
    };
    dispatch(actions.setClientSetUp(clientSetUp));
    if (domainInfo) {
      // upon init start authentication process
      authentication(dispatch, clientSetUp); // auth code grant
    } else {
      dispatch(actions.setAuthenticating(false));
    }
  };
  // run application initialization function after component mount
  useEffect(() => {
    clientSetupInit();
  }, []);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {(state.authenticating || state.loadingTdData) && <LoadingMask />}
      <section className="application-container">
        <AppHeader />
        <AppBody />
      </section>
    </AppContext.Provider>
  );
}
export default App;
