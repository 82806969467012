/**
 * @file Define the function handlers for each dispatch actions
 */
import actionTypes from "./actionTypes";

export const initialState = {
  pcParams: {},
  userInfo: {},
  clientSetUp: {},
  conversationData: {},
  customerHandle: {},
  authenticating: true,
  loadingTdData: false,
};
const reducer = (state = initialState, action) => {
  const { data } = action;
  switch (action.type) {
    case actionTypes.SET_AUTHENTICATING: {
      return {
        ...state,
        authenticating: data,
      };
    }
    case actionTypes.SET_LOADING_TD_DATA: {
      return {
        ...state,
        loadingTdData: data,
      };
    }
    case actionTypes.SET_CLIENT_SET_UP: {
      return {
        ...state,
        clientSetUp: data,
      };
    }
    case actionTypes.SET_AGENTDATA: {
      const { userInfo } = data;
      return {
        ...state,
        userInfo,
      };
    }
    case actionTypes.SET_CONVERSATION_INFO: {
      const { conversationData } = data;
      const { conversationData: oldConversationData, genesysConversationData } =
        state;
      const checkValidLength = (item) => {
        return item.length > 0;
      };
      const newConversationData = {
        ...oldConversationData,
        ...conversationData,
      };
      // replace the old data if new data exist, otherwise keep the old data
      for (const property in genesysConversationData) {
        newConversationData[property] =
          newConversationData[property] &&
          checkValidLength(newConversationData[property])
            ? newConversationData[property]
            : genesysConversationData[property];
      }
      return {
        ...state,
        conversationData: newConversationData,
      };
    }
    case actionTypes.SET_GENESYS_CONVERSATION_INFO: {
      const { genesysConversationData } = data;
      return {
        ...state,
        genesysConversationData,
      };
    }
    case actionTypes.SET_ERROR_STATE: {
      const { errorState } = data;
      return {
        ...state,
        errorState,
      };
    }
    case actionTypes.SET_CUSTOMER_HANDLE: {
      return {
        ...state,
        customerHandle: data,
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
