import React from "react";

const LoadingSvgComponent = () => {
  return (
    <svg
      width="113"
      height="116"
      viewBox="0 0 113 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="preloader">
        <path
          id="diamond"
          d="M81.4056 51H33.7684C33.2594 51 32.7717 51.2045 32.4149 51.5677L21.7272 62.4462C20.9016 63.2866 20.9016 64.6334 21.7272 65.4738L56.8971 101.272C57.7436 102.133 59.1322 102.133 59.9787 101.272L94.3528 66.2838C95.1784 65.4434 95.1784 64.0966 94.3528 63.2562L82.9464 51.6462C82.5403 51.2329 81.9851 51 81.4056 51Z"
          fill="#F4F4F4"
          stroke="#F4F4F4"
          strokeWidth="6.48"
        />
        <g id="mask-group-back">
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="-14"
            y="54"
            width="686"
            height="109"
          >
            <path
              id="wave-fill-back"
              d="M-14 163V54.4999C1.5 52.9999 4.5 60.5 24 65C36.7507 67.9425 44.0079 60.176 56.5 59.3857C70.5 58.4999 77 69.6745 89.5 68.9999C100.5 68.4063 100 60.2715 115 59.3857C122.487 58.9436 135.537 67.7705 143.5 66.9999C159 65.4999 158 59.3857 171.5 58.4999C185 57.6142 189 71.0117 206 69.4999C218 68.4328 230 56.4999 239 56.4999C253.319 56.4999 259 70.067 274.5 70.067C290 70.067 303 55.2319 320.5 55.2319C338 55.2319 340.187 75.4999 367 70.067C387.5 65.9132 387.5 53 404 55.2319C420.5 57.4637 426 70.067 440.5 70.067C457 70.067 454 67.2715 475.5 59.3857C497 51.4999 501.5 72.567 518.5 70.067C535.5 67.567 540.5 54 560 54C579.5 54 582.22 73.1739 604 70.067C625.5 66.9999 624.5 59.3857 638 59.3857C652 59.3857 650 71.4999 672 75.4999V163L234 163H-14Z"
              fill="url(#paint0_linear)"
            />
          </mask>
          <g mask="url(#mask0)">
            <path
              id="wave-mask-back"
              d="M81.423 50H31.6737C32.0957 50 32.3085 50.5089 32.0122 50.8093L20.4866 62.493C19.6608 63.3302 19.6564 64.6741 20.4767 65.5167L56.8837 102.91C57.7318 103.781 59.1313 103.781 59.9792 102.91L95.5237 66.3917C96.3439 65.5491 96.3394 64.2053 95.5136 63.3682L82.9607 50.6431C82.5548 50.2316 82.0009 50 81.423 50Z"
              fill="url(#paint1_linear)"
            />
          </g>
        </g>
        <g id="mask-group-front">
          <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="16"
            y="50"
            width="639"
            height="113"
          >
            <path
              id="wave-fill-front"
              d="M16 163V62.9463C36 46.9243 42.0009 64.287 62.5 65.9133C82.5 67.5 89.5 55.232 99 55.232C116 55.232 120 63.6208 132.5 62.9463C143.5 62.3526 147.5 52.8583 162.5 55.232C177.5 57.6056 180.962 66.5598 195 65C209.038 63.4402 209 55.232 223 57C231.454 58.0676 240.5 67.5 249 67.5C265 67.5 266.483 60.5159 284.5 57C298.5 54.268 302 70.0671 327.5 70.0671C353 70.0671 362 55.5 380 55.5C398 55.5 414.5 74.2209 432.5 70.0671C450.5 65.9133 457.5 52.5001 478 50.5001C491 49.2318 498.5 65.9133 523.5 65.9133C548.5 65.9133 553.5 50.5001 571 50.5001C585 50.5001 602.5 70.0671 621.5 70.0671C640.5 70.0671 637.5 55.232 655 55.232V163H264H16Z"
              fill="url(#paint2_linear)"
            />
          </mask>
          <g mask="url(#mask1)">
            <path
              id="wave-mask-front"
              d="M81.423 50H31.6737C32.0957 50 32.3085 50.5089 32.0122 50.8093L20.4866 62.493C19.6608 63.3302 19.6564 64.6741 20.4767 65.5167L56.8837 102.91C57.7318 103.781 59.1313 103.781 59.9792 102.91L95.5237 66.3917C96.3439 65.5491 96.3394 64.2053 95.5136 63.3682L82.9607 50.6431C82.5548 50.2316 82.0009 50 81.423 50Z"
              fill="url(#paint3_linear)"
            />
          </g>
        </g>
        <g id="circles">
          <g id="col-2">
            <circle
              id="col2-small-circle"
              cx="72.5"
              cy="4.5"
              r="4.5"
              fill="#F9C0AD"
            />
          </g>
          <g id="col-1">
            <circle id="col1-big-circle" cx="43" cy="6" r="6" fill="#BED1E3" />
            <circle
              id="col1-small-circle"
              cx="58.5"
              cy="4.5"
              r="4.5"
              fill="#9EB9D5"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="329"
          y1="70.9997"
          x2="326.49"
          y2="161.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91BAE1" stopOpacity="0.6" />
          <stop offset="0.484375" stopColor="#78AAE0" stopOpacity="0.6" />
          <stop offset="0.911458" stopColor="#6794C3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="58"
          y1="65"
          x2="64.5"
          y2="92.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4C7EE" stopOpacity="0.3" />
          <stop offset="0.484375" stopColor="#6493C6" stopOpacity="0.6" />
          <stop offset="0.911458" stopColor="#5480AD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="271"
          y1="-30"
          x2="292.077"
          y2="227.971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5C9EF" stopOpacity="0.3" />
          <stop offset="0.619792" stopColor="#6B94C0" stopOpacity="0.6" />
          <stop offset="1" stopColor="#5480AD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="58"
          y1="52"
          x2="64.4476"
          y2="104.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#85AFDE" stopOpacity="0.3" />
          <stop offset="0.484375" stopColor="#90B5DD" stopOpacity="0.6" />
          <stop offset="1" stopColor="#6794C4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default LoadingSvgComponent;
