/**
 * @file all globally used constants can be defined here
 */
const DEFAULT_PC_ENVIRONMENT = "usw2.pure.cloud";

const WHITELISTED_PC_ENVIRONMENT = [
  "mypurecloud.com",
  "use2.us-gov-pure.cloud",
  "usw2.pure.cloud",
  "cac1.pure.cloud",
  "mypurecloud.de",
  "mypurecloud.ie",
  "euw2.pure.cloud",
  "aps1.pure.cloud",
  "apne2.pure.cloud",
  "mypurecloud.com.au",
  "mypurecloud.jp",
];

export { DEFAULT_PC_ENVIRONMENT, WHITELISTED_PC_ENVIRONMENT };
