/**
 * @file Contains useful helper functions
 */
import CryptoJS from "crypto-js";
import actions from "../context/actions";

/**
 * send request to /interaction endpoint to get TD customer data
 * @param {Object} customerParam custom handler to pass to get TD customer data
 * @param {function} dispatch react hook dispatch function
 * @param {Object} clientSetUp global state of client set up
 */
const getDataFromTd = (customerParam, dispatch, clientSetUp) => {
  const { redirect_uri } = clientSetUp;
  dispatch(actions.setLoadingTdData(true));
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", "ex1wx8MYM02yRdOo2eClB1Aa8rcinmDP82n2bEup");
  myHeaders.append("authorizationToken", "abc123");
  // var URL = "https://domain-authentication-server.com/interaction";
  var URL = "https://evening-lake-43122.herokuapp.com/https://9uzgxzyxr0.execute-api.us-east-1.amazonaws.com/test/new_markets_genesys_demo/";
  const requestBodyObj = {
    ...customerParam,
    // domain_name: redirect_uri,
    // access_token: generateAccessToken(clientSetUp),
  };
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestBodyObj),
  };
  fetch(URL, requestOptions)
    .then(function (response) {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then(function (data) {
      console.log('Z:', data)
      dispatch(
        actions.setConversationInfo({
          conversationData: data,
        })
      );
      dispatch(actions.setLoadingTdData(false));
      makeTablefromTd(data, dispatch);
    })
    .catch((error) => {
      dispatch(actions.setLoadingTdData(false));
      console.log("error", error);
    });
};
/**
 * fileter the input array to remove duplicate items
 * @param {Object[]} arr Array to iterrate through
 * @returns {Object[]} Array filtered without duplicate items
 */
const removeArrayDuplicates = (arr) => {
  const uniqDictionary = new Set();
  return arr.reduce(function (uniqArr, value) {
    if (value && !uniqDictionary.has(value.replace(/\s+/g, "").toLowerCase())) {
      uniqDictionary.add(value.replace(/\s+/g, "").toLowerCase());
      uniqArr.push(value);
    }
    return uniqArr;
  }, []);
};
/**
 * manipulate and formalize the customer data for display
 * @param {Object} result get TD customer API result
 * @param {function} dispatch react hook dispatch function
 */
function makeTablefromTd(result, dispatch) {
  var newResult = result;
  const conversationData = {};
  if (typeof newResult !== "undefined" && newResult !== null) {
    for (var key in newResult) {
      if (
        newResult.hasOwnProperty(key) &&
        typeof newResult[key] !== "undefined" &&
        newResult[key] !== null
      ) {
        if (key === "Customer_ltv" && newResult[key] !== "") {
          conversationData.CLTV =
            Math.round((newResult[key] + Number.EPSILON) * 100) / 100;
        } else if (
          key === "loyalty_member" &&
          typeof newResult["Customer_ltv"] !== "undefined" &&
          newResult["Customer_ltv"] !== null
        ) {
          if (newResult["Customer_ltv"] !== "") {
            var CLTV = Math.round(newResult["Customer_ltv"]);
            conversationData.score = (CLTV * 2) / 10;
          } else if (newResult["Customer_ltv"] === "") {
            conversationData.score = "0";
          }
        }
      }
    }
  }
  dispatch(
    actions.setConversationInfo({
      conversationData,
    })
  );
}
/**
 * genearate access token for dynamic value that's passed in
 * @param {Object} clientSetUp global state of client set up
 * @returns {string} hash value of provided data in a certain pattern
 */
const generateAccessToken = (clientSetUp = {}) => {
  const {
    genesys_access_token = "",
    pcEnvironment = "",
    account_id = "",
    redirect_uri,
  } = clientSetUp;
  const now_utc = new Date(new Date().toUTCString().slice(0, -4));
  const formattedDate =
    (now_utc.getMonth() > 8
      ? now_utc.getMonth() + 1
      : "0" + (now_utc.getMonth() + 1)) +
    "/" +
    (now_utc.getDate() > 9 ? now_utc.getDate() : "0" + now_utc.getDate()) +
    "/" +
    now_utc.getFullYear();
  return CryptoJS.SHA256(
    encodeURI(
      redirect_uri +
        account_id +
        pcEnvironment +
        genesys_access_token +
        formattedDate
    )
  ).toString(CryptoJS.enc.Hex);
};
/**
 * send request to /get/domain endpoint to get the full domain information
 * @param {string} redirect_uri current domain uri
 * @param {string} pcEnvironment current environemnt
 * @returns
 */
const getDomainInfo = async (redirect_uri, pcEnvironment) => {
  var myHeaders = new Headers();
  const access_token = generateAccessToken({ redirect_uri, pcEnvironment });
  myHeaders.append("Content-Type", "application/json");
  const params = {
    access_token,
    domain_name: redirect_uri,
  };
  var URL = "https://domain-authentication-server.com/get/domain";
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };
  let response = null;
  try {
    response = await fetch(URL, requestOptions);
  } catch (error) {
    console.log("error", error);
    return response;
  }
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    console.log("error", message);
    return null;
  }
  const domainInfo = await response.json();
  return domainInfo;
};
/**
 * Check the window(browser) to see if localStorage's availablility, return the result accordingly
 * @returns {boolean} true if localStorage is available, false otherise
 */
const checkSupportsHtml5Storage = () => {
  try {
    return "localStorage" in window && window["localStorage"] !== null;
  } catch (e) {
    return false;
  }
};
export {
  getDataFromTd,
  removeArrayDuplicates,
  generateAccessToken,
  getDomainInfo,
  checkSupportsHtml5Storage,
};
