import React, { useContext } from "react";
import AppContext from "../context/store";
import LoadingSvgComponent from "./LoadingSvg";
import "./LoadingMask.css";

const LoadingMask = () => {
  const { state } = useContext(AppContext);
  return (
    <>
      <section className="authenticating">
        <div id="diamond">
          <div className="preloaderImg"></div>
          <LoadingSvgComponent />
          <div className="loading">
            {(state.authenticating && "Authenticating to Genesys Cloud ") ||
              (state.loadingTdData && "Loading data from TD CDP ")}
          </div>
        </div>
      </section>

      <section className="failure" hidden>
        Sorry, an error has occurred.
      </section>
    </>
  );
};

export default LoadingMask;
