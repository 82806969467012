/**
 * @file contains all functions to initially authenticate the current agent
 */
import actions from "../context/actions";
import { getDataFromTd, generateAccessToken } from "./helpers";
import { WHITELISTED_PC_ENVIRONMENT } from "./constant";

/**
 * step 1, /authorize endpoint
 * @param {function} dispatch react global store dispatch function
 * @param {Object} clientSetUp global variable of client set up
 */
const authentication = async (dispatch, clientSetUp) => {
  const { code, pcEnvironment, conversationId, redirect_uri, client_id } =
    clientSetUp;
  // if the page is loaded with code param, means we want to try and exchange it for token
  if (code) {
    getToken(dispatch, clientSetUp);
  } else {
    //redirect the user to authorize with Genesys Cloud
    if (WHITELISTED_PC_ENVIRONMENT.includes(pcEnvironment)) {
      const redirectUri =
        `https://login.${pcEnvironment}/oauth/authorize?` +
        "response_type=code" +
        "&client_id=" +
        client_id +
        "&redirect_uri=" +
        redirect_uri +
        "&state=" +
        conversationId;
      window.location.replace(redirectUri);
    }
  }
};
/**
 * step 2, /oauth/token endpint
 * @param {function} dispatch react global store dispatch function
 * @param {Object} clientSetUp global variable of client set up
 */
const getToken = (dispatch, clientSetUp) => {
  const { code } = clientSetUp;
  // clear access_id for endpoint call
  const accessTokenObj = { ...clientSetUp, account_id: "" };
  const params = {
    // auth code grant
    code: code,
    access_token: generateAccessToken(accessTokenObj),
  };
  fetch("https://domain-authentication-server.com/oauth/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((tokenResponse) => {
      if (tokenResponse.access_token) {
        // add access token information to the global store
        const clientSetUpObj = {
          ...clientSetUp,
          genesys_access_token: tokenResponse.access_token,
        };
        dispatch(actions.setClientSetUp(clientSetUpObj));
        getAgent(dispatch, clientSetUpObj);
      }
      dispatch(actions.setAuthenticating(false));
    })
    .catch((e) => console.error(e));
};
/**
 * Send information and request to /logging endpoint for logging purpose
 * @param {Object} agentInfo result of /me endpont contains agent inforamtion
 * @param {Object} clientSetUp global variable of client set up
 */
const logAgentInfo = (agentInfo, clientSetUp) => {
  const {
    id: agent_id,
    name: agent_name,
    username: agent_username,
  } = agentInfo;
  const { genesys_access_token = "" } = clientSetUp;
  // clear access_id for endpoint call
  const params = {
    // auth code grant
    agent_id,
    agent_name,
    agent_username,
    genesys_access_token,
  };
  fetch("https://domain-authentication-server.com/logging", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params), // auth code grant
  })
    .then((res) => res.json())
    .then((loggerResponse) => {
      console.log("logger response: ", loggerResponse);
    })
    .catch((e) => console.error(e));
};
/**
 * step 3, /me endpiont
 * @param {function} dispatch react global store dispatch function
 * @param {Object} clientSetUp global variable of client set up
 */
const getAgent = (dispatch, clientSetUp) => {
  const { genesys_access_token: access_token, pcEnvironment } = clientSetUp;
  let authenticated = false;
  let userDataAcquired = false;
  let canViewInteraction = false;
  let canViewWrapUpCodes = false;
  fetch(
    "https://api." + pcEnvironment + "/api/v2/users/me?expand=authorization",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + access_token,
      },
    }
  )
    .then(function (response) {
      if (response.ok) {
        userDataAcquired = true;
        return response.json();
      }
      return Promise.reject(response);
    })
    .then(function (result) {
      authenticated = true;
      dispatch(
        actions.setAgentData({
          userInfo: result,
        })
      );
      // ping server with agent infor for logging
      logAgentInfo(result, clientSetUp);

      let permissions = result.authorization.permissions;
      if (
        checkPermission(permissions, "conversation:communication:view") &&
        checkPermission(permissions, "externalContacts:contact:view")
      ) {
        // call function to send TD database to grab customer information if agent has the right permission
        canViewInteraction = true;
        getConvoWithId(dispatch, clientSetUp);
      }
      if (checkPermission(permissions, "routing:wrapupCode:view")) {
        canViewWrapUpCodes = true;
      }
    })
    .catch(function (err) {
      if (!authenticated) {
        dispatch(
          actions.setErrorState(
            "Failed to Authenticate with Genesys Cloud - " + err.message
          )
        );
      } else if (!userDataAcquired) {
        dispatch(
          actions.setErrorState("Failed to locate user in Genesys Cloud")
        );
      }
    });
};
/**
 * step 4 /conversation endpoint, get conversation with genesys acces token
 * @param {function} dispatch react global store dispatch function
 * @param {Object} clientSetUp global variable of client set up
 */
const getConvoWithId = (dispatch, clientSetUp) => {
  const {
    pcEnvironment,
    conversationId,
    genesys_access_token: access_token,
  } = clientSetUp;
  fetch(
    "https://api." + pcEnvironment + "/api/v2/conversations/" + conversationId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + access_token,
      },
    }
  )
    .then(function (response) {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then(function (data) {
      if (data.hasOwnProperty("entities") && data.entities.length > 0) {
        // find matching conversation entity, otherwise return the first entity
        let currentConversation = data.entities[0];
        for (let i = 0; i < data.entities.length; i++) {
          if (data.entities[i] && data.entities[i].id === conversationId) {
            currentConversation = data.entities[i];
            break;
          }
        }
        updateConversationInfo(currentConversation, dispatch, clientSetUp);
      } else {
        updateConversationInfo(data, dispatch, clientSetUp);
      }
    });
};
/**
 * step 5 helper, get customer info with external custom handler
 * @param {Object} data result data from get convo form Genesys
 * @param {function} dispatch react global store dispatch function
 * @param {Object} clientSetUp global variable of client set up
 */
const handleExternalContactResponse = (data, dispatch, clientSetUp) => {
  let customerHandle,
    paramKey = "";
  if ("workEmail" in data) {
    customerHandle = data.workEmail;
    paramKey = "email";
  } else if ("personalEmail" in data) {
    customerHandle = data.personalEmail;
    paramKey = "email";
  } else if ("otherEmail" in data) {
    customerHandle = data.otherEmail;
    paramKey = "email";
  } else if ("workPhone" in data) {
    customerHandle = data.workPhone;
    paramKey = "phone";
  } else if ("cellPhone" in data) {
    customerHandle = data.cellPhone;
    paramKey = "phone";
  } else if ("homePhone" in data) {
    customerHandle = data.homePhone;
    paramKey = "phone";
  } else if ("customFields" in data) {
    if (
      "email_text" in data.customFields &&
      !("tdclientid_identifier" in data.customFields)
    ) {
      customerHandle = data.customFields.email_text;
      paramKey = "email";
    } else if ("phone_text" in data.customFields) {
      customerHandle = data.customFields.phone_text;
      paramKey = "phone";
    } else if (
      "tdclientid_identifier" in data.customFields &&
      !("email_text" in data.customFields)
    ) {
      customerHandle = data.customFields.tdclientid_identifier;
      paramKey = "td_client_id";
    } else if (
      "tdclientid_identifier" in data.customFields &&
      "email_text" in data.customFields
    ) {
      var handleArray = [];
      var paramArray = [];
      handleArray.push(data.customFields.email_text);
      handleArray.push(data.customFields.tdclientid_identifier);
      customerHandle = handleArray;
      paramArray.push("email");
      paramArray.push("td_client_id");
      paramKey = paramArray;
    }
  }
  if (
    typeof customerHandle !== "undefined" &&
    customerHandle !== null &&
    customerHandle !== ""
  ) {
    const customerParam = { key: paramKey, value: customerHandle };
    dispatch(actions.setCustomerHandle(customerParam));
    getDataFromTd(customerParam, dispatch, clientSetUp);
  }
};
/**
 * step 5, get customer info with custom handler
 * @param {Object} data result data from get convo form Genesys
 * @param {function} dispatch react global store dispatch function
 * @param {Object} clientSetUp global variable of client set up
 */
const updateConversationInfo = (data, dispatch, clientSetUp) => {
  let customerHandle,
    paramKey = "";
  const { id, participants } = data;
  const { pcEnvironment, genesys_access_token: access_token } = clientSetUp;
  let profile = {};
  if (
    typeof participants !== "undefined" &&
    participants !== null &&
    participants !== ""
  ) {
    for (let i = 0; i < participants.length; i++) {
      if (participants[i].purpose === "customer") {
        var media;
        const { calls, chats, emails, name, attributes, address } =
          participants[i];
        if (typeof name !== "undefined" && name !== null) {
          profile.name = name;
        }
        if (calls && calls.length > 0) {
          media = "call";
        } else if (chats && chats.length > 0) {
          media = "chat";
        } else if (emails && emails.length > 0) {
          media = "email";
        }
        // if ("externalContactId" in participants[i]) {
        //   var externalId = participants[i].externalContactId;
        //   fetch(
        //     "https://api." +
        //       pcEnvironment +
        //       "/api/v2/externalcontacts/contacts/" +
        //       externalId,
        //     {
        //       method: "GET",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: "bearer " + access_token,
        //       },
        //     }
        //   )
        //     .then(function (response) {
        //       if (response.ok) {
        //         return response.json();
        //       }
        //       return Promise.reject(response);
        //     })
        //     .then((result) => {
        //       handleExternalContactResponse(result, dispatch, clientSetUp);
        //     })
        //     .catch(function (err) {
        //       dispatch(
        //         actions.setErrorState(
        //           "Failed to Load Data from Treasure Data - " + err.message
        //         )
        //       );
        //     });
        // } else {
          if (
            typeof attributes !== "undefined" &&
            attributes !== null &&
            attributes !== ""
          ) {
            if (media === "chat") {
              if (
                attributes["context.email"] == null ||
                typeof attributes["context.email"] == "undefined" ||
                attributes["context.email"] === ""
              ) {
                if (
                  attributes["context.customField1"] !== null &&
                  typeof attributes["context.customField1"] !== "undefined" &&
                  attributes["context.customField1"] !== ""
                ) {
                  customerHandle = attributes["context.customField1"];
                  paramKey = attributes["context.customField1Label"];
                }
              } else {
                if (
                  attributes["context.customField1"] !== null &&
                  typeof attributes["context.customField1"] !== "undefined" &&
                  attributes["context.customField1"] !== "" &&
                  attributes["context.email"] !== null &&
                  typeof attributes["context.email"] !== "undefined" &&
                  attributes["context.email"] !== ""
                ) {
                  var handleArray = [];
                  var paramArray = [];
                  handleArray.push(attributes["context.email"]);
                  handleArray.push(attributes["context.customField1"]);
                  customerHandle = handleArray;
                  paramArray.push("email");
                  paramArray.push(attributes["context.customField1Label"]);
                  paramKey = paramArray;
                } else if (
                  attributes["context.customField1"] == null ||
                  typeof attributes["context.customField1"] == "undefined" ||
                  attributes["context.customField1"] === ""
                ) {
                  customerHandle = attributes["context.email"];
                  paramKey = "email";
                }
              }
            } else if (media === "call") {
              customerHandle = address;
              customerHandle = parseInt(customerHandle.replace(/[^0-9]/g, ""));
              customerHandle = customerHandle.toString();
              paramKey = "phone";
            } else if (media === "email") {
              customerHandle = address;
              paramKey = "email";
            }
            if (
              typeof customerHandle !== "undefined" &&
              customerHandle !== null &&
              customerHandle !== ""
            ) {
              const customerParam = { key: paramKey, value: customerHandle };
              for (const key in paramKey) {
                profile[paramKey[key]] = customerHandle[key];
              }
              dispatch(actions.setCustomerHandle(customerParam));
              getDataFromTd(customerParam, dispatch, clientSetUp);
            }
          }
      }
    }
  }
  dispatch(
    actions.setConversationInfo({
      conversationData: {
        data,
        id,
        ...profile,
      },
    })
  );
  dispatch(
    actions.setGenesysConversationInfo({
      genesysConversationData: {
        data,
        id,
        ...profile,
      },
    })
  );
};
/**
 * check if is target item
 * @param {string} item
 * @param {string} targetItem
 * @returns {boolean} result of target check comparison
 */
function isPermission(item, targetItem) {
  let isItem = item === "*" || targetItem === "*";
  if (!isItem) {
    isItem = item === targetItem;
  }
  return isItem;
}
/**
 * Parse the permissions array and check whether or not the match the specified required ones
 * @param {Object[]} permissions array of permissions
 * @param {string} permissionValue matching pattern
 * @returns indicating if the user possesses the required permissions
 */
function checkPermission(permissions, permissionValue) {
  let isAllowed = false;

  if (!permissions) {
    permissions = [];
  }

  if (permissionValue.match(/^[a-zA-Z0-9]+:\*$/)) {
    permissionValue = permissionValue.replace(/\*/g, "*:*");
  }

  const permissionsToValidate = permissionValue.split(":"),
    targetDomain = permissionsToValidate[0],
    targetEntity = permissionsToValidate[1],
    targetAction = permissionsToValidate[2];

  permissions.forEach(function (permission) {
    const permissions = permission.split(":"),
      domain = permissions[0],
      entity = permissions[1],
      actionSet = permissions[2];

    if (targetDomain === domain) {
      const matchesEntity = isPermission(targetEntity, entity),
        matchesAction = isPermission(targetAction, actionSet);

      if (matchesEntity && matchesAction) {
        isAllowed = true;
      }
    }
  });

  return isAllowed;
}
export { authentication };
