/**
 * @file Define the name of possible disaptch actions
 */
const actionTypes = {
  SET_ERROR_STATE: "SET_ERROR_STATE",
  SET_AUTHENTICATING: "SET_AUTHENTICATING",
  SET_CLIENT_SET_UP: "SET_CLIENT_SET_UP",
  SET_LOADING_TD_DATA: "SET_LOADING_TD_DATA",
  SET_AGENTDATA: "SET_AGENTDATA",
  SET_CONVERSATION_INFO: "SET_CONVERSATION_INFO",
  SET_GENESYS_CONVERSATION_INFO: "SET_GENESYS_CONVERSATION_INFO",
  SET_CUSTOMER_HANDLE: "SET_CUSTOMER_HANDLE",
};
export default actionTypes;
