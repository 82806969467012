/**
 * @file Initialize possible dispath actions for global storage
 */
import actionTypes from "./actionTypes";

const actions = {
  setAuthenticating: (data) => ({
    type: actionTypes.SET_AUTHENTICATING,
    data,
  }),
  setLoadingTdData: (data) => ({
    type: actionTypes.SET_LOADING_TD_DATA,
    data,
  }),
  setClientSetUp: (data) => ({
    type: actionTypes.SET_CLIENT_SET_UP,
    data,
  }),
  setAgentData: (data) => ({
    type: actionTypes.SET_AGENTDATA,
    data,
  }),
  setConversationInfo: (data) => ({
    type: actionTypes.SET_CONVERSATION_INFO,
    data,
  }),
  setGenesysConversationInfo: (data) => ({
    type: actionTypes.SET_GENESYS_CONVERSATION_INFO,
    data,
  }),
  setErrorState: (data) => ({
    type: actionTypes.SET_ERROR_STATE,
    data,
  }),
  setCustomerHandle: (data) => ({
    type: actionTypes.SET_CUSTOMER_HANDLE,
    data,
  }),
};
export default actions;
